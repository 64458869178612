<template>
    <div class="main-container">
        <div class="header-title"><span class="title-text">智农农业产业园</span> </div>
        <van-tabs v-model="activeTab" scrollspy sticky class="top-tab" ref="tabs">
            <div slot="nav-right">
                <div class="top-title-right" @click="openNav" id="openNav">
                    <img src="../assets/images/home/top-title-right.png"/>
                    <van-dropdown-menu :overlay="false">
                        <van-dropdown-item v-model="val1" ref="menu" :options="option1" @change="changeMenu" />
                    </van-dropdown-menu>
                </div>
            </div>
            
            <!-- 产业概况 -->
            <van-tab title="产业概况" class="item-tab" :name="0">
                <div class="item">
                    <div class="title">产业概况</div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content industry-overview">
                        <div :class="[{'box': showBtn}, 'desc-content']" id="desc" :content="describe">
                            {{describe}}
                        </div>
                        <div class="divider-bg"></div>
                        <div class="img-bg industry-bg">
                            <img :src="describeSrc"/>
                        </div>
                    </div>
                    <div class="divider bottom-divider"></div>
                </div>
            </van-tab>
            <!-- 经济形态 -->
            <van-tab title="经济形态" class="item-tab" :name="1">
                <div class="item">
                    <div class="title">经济形态</div>
                    <div class="content economic" >
                        <div v-for="(item, index) in economicImg" :key="index">
                            <div :class="{'img-container': true, 'prev': index == 0, 'active': index == 1, 'next': index == 2}">
                                <img :src="item.url"/>
                            </div>
                            <div class="img-desc" v-if="index == 1">{{item.desc}}</div>
                        </div>
                    </div>
                </div>
            </van-tab> 
            <!-- 产业布局 -->
            <van-tab title="产业布局" class="item-tab" :name="2">
                <div class="item">
                    <div class="title">产业布局</div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content industry-overview">  
                        <div class="desc-content" :content="distribution">
                            {{distribution}}
                        </div>
                    </div>
                    <div class="divider bottom-divider"></div>
                </div>
            </van-tab> 
            <van-tab v-for="(item, index) in orderArr" :key="index" :title="item.title" class="item-tab" :name="item.name">
               <!--   <div class="item" v-if="item.id == 0">
                    <div class="title">产业概况</div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content industry-overview">
                        <div :class="[{'box': showBtn}, 'desc-content']" id="desc" :content="describe">
                            {{describe}}
                        </div>
                        <div class="divider-bg"></div>
                        <div class="img-bg industry-bg">
                            <img :src="describeSrc"/>
                        </div>
                    </div>
                    <div class="divider bottom-divider"></div>
                </div>
                <div class="item" v-if="item.id == 1">
                    <div class="title">经济形态</div>
                    <div class="content economic" >
                        <div v-for="(item, index) in economicImg" :key="index">
                            <div :class="{'img-container': true, 'prev': index == 0, 'active': index == 1, 'next': index == 2}">
                                <img :src="item.url"/>
                            </div>
                            <div class="img-desc" v-if="index == 1">{{item.desc}}</div>
                        </div>
                    </div>
                </div>
                <div class="item" v-if="item.id == 2">
                    <div class="title">产业布局</div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content industry-overview">  
                        <div class="desc-content" :content="distribution">
                            {{distribution}}
                        </div>
                    </div>
                    <div class="divider bottom-divider"></div>
                </div> -->
                <div class="item" id="products" v-show="item.name == 3">
                    <div class="title">特色产品</div>
                    <div class="content products">
                        <div :class="{'products-item': true, 'm-r0': (index + 1) % 3 == 0}" v-for="(item, index) in products" :key="index">
                            <div class="products-img">
                                <img :src="item.url"/>
                            </div>
                            <p><span>{{ item.name }}</span></p>
                        </div>
                    </div>
                </div>
                 <div class="item" v-if="item.name == 4">
                    <div class="title">价格行情
                        <div class="title-right">
                            <span class="yoy-growth">同比增长</span>
                            <span class="chain-growth">环比增长</span>
                        </div>
                    </div>
                    <div class="content price-quotation">  
                        <div ref="price" class="price" id="priceTrend"></div>
                        <div class="type-price">
                            <span :class="{'type-item': true, 'active': typeFlag == 1}" @click="changeType('1')">大豆</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 2}" @click="changeType('2')">小麦</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 3}" @click="changeType('3')">苹果</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 4}" @click="changeType('4')">脐橙</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 5}" @click="changeType('5')">玉米</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 6}" @click="changeType('6')">香梨</span>
                        </div>
                    </div>
                </div>
                <div class="item" v-if="item.name == 5">
                    <div class="title">农事记事<span class="products-more" @click="jumpCrop" v-if="!isTourist">更多>></span></div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content farming">
                        <div class="farming-item" v-for="(item, index) in farmingNotes" :key="index">
                            <div class="left">
                                <img :src="item.url"/>
                                <div class="vertical-line"></div>
                            </div>
                            <div class="right">
                                <p class="right-title">{{item.context}}</p>
                                <p class="right-other"><span>{{item.createTime}}</span><span>投入品: {{item.inputsName}}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                </div>
                <div class="item" v-if="item.name == 6">
                    <div class="title">印象风采</div>
                    <div class="content impression">
                        <div class="impression-img">
                            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                                <van-swipe-item v-for="(item, index) in swiperList" :key="index">
                                    <img :src="item"/>
                                </van-swipe-item>
                            </van-swipe>
                        </div>
                        <p class="impression-bottom"><span></span><span></span></p>
                    </div>
                </div>
                <div class="item" v-if="item.name == 7">
                    <div class="title">多元资讯
                        <div class="consulting-title">
                            <span :class="{'active': consultingActive == 1}" @click="changeConsulting(1)">市场营销</span>
                            <span :class="{'active': consultingActive == 2}" @click="changeConsulting(2)">政策咨询</span>
                            <span :class="{'active': consultingActive == 3}" @click="changeConsulting(3)">惠农助农</span>
                        </div>
                    </div>
                    <div class="content consulting">
                        <p v-for="(item, index) in consultingList" :key="index">
                            <a :href="item.url">{{item.content}}</a>
                            <span>{{item.time}}</span>
                        </p>
                    </div>
                </div>
                <div class="item" id="location" v-if="item.name == 8">
                    <div class="title">通讯地址</div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content mailing">
                        <div class="map-container">
                            <div class="map" id="map"></div>
                        </div>
                        <div class="contcat"> 
                            <p><span>联系电话:</span><span>{{companyInfo.companyPhone}}</span></p>
                            <p><span>企业邮箱:</span><span>{{companyInfo.companyEmail}}</span></p>
                            <p><span>企业地址:</span><span>{{companyInfo.companyAddress}}</span></p>
                        </div>
                    </div>
                    <div class="end-bottom"></div>
                </div>
             </van-tab>
            <!-- 特色产品 -->
            <!-- <van-tab title="特色产品" class="item-tab" :name="3">
                <div class="item" id="products">
                    <div class="title">特色产品</div>
                    <div class="content products">
                        <div :class="{'products-item': true, 'm-r0': (index + 1) % 3 == 0}" v-for="(item, index) in products" :key="index">
                            <div class="products-img">
                                <img :src="item.url"/>
                            </div>
                            <p><span>{{ item.name }}</span></p>
                        </div>
                    </div>
                </div>
            </van-tab> -->
            <!-- 价格行情 -->
            <!-- <van-tab title="价格行情" class="item-tab" :name="4">
                <div class="item">
                    <div class="title">价格行情
                        <div class="title-right">
                            <span class="yoy-growth">同比增长</span>
                            <span class="chain-growth">环比增长</span>
                        </div>
                    </div>
                    <div class="content price-quotation">  
                        <div ref="price" class="price"></div>
                        <div class="type-price">
                            <span :class="{'type-item': true, 'active': typeFlag == 1}" @click="changeType('1')">大豆</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 2}" @click="changeType('2')">小麦</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 3}" @click="changeType('3')">苹果</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 4}" @click="changeType('4')">脐橙</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 5}" @click="changeType('5')">玉米</span>
                            <span :class="{'type-item': true, 'active': typeFlag == 6}" @click="changeType('6')">香梨</span>
                        </div>
                    </div>
                </div>
            </van-tab> -->
            <!-- 农事记事 -->
            <!-- <van-tab title="农事记事" class="item-tab" :name="5">
                <div class="item">
                    <div class="title">农事记事<span class="products-more" @click="jumpCrop" v-if="!isTourist">更多>></span></div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content farming">
                        <div class="farming-item" v-for="(item, index) in farmingNotes" :key="index">
                            <div class="left">
                                <img :src="item.url"/>
                                <div class="vertical-line"></div>
                            </div>
                            <div class="right">
                                <p class="right-title">{{item.context}}</p>
                                <p class="right-other"><span>{{item.createTime}}</span><span>投入品: {{item.inputsName}}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                </div>
            </van-tab> -->
            <!-- 印象风采 -->
            <!-- <van-tab title="印象风采" class="item-tab" :name="6">
                <div class="item">
                    <div class="title">印象风采</div>
                    <div class="content impression">
                        <div class="impression-img">
                            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                                <van-swipe-item v-for="(item, index) in swiperList" :key="index">
                                    <img :src="item"/>
                                </van-swipe-item>
                            </van-swipe>
                        </div>
                        <p class="impression-bottom"><span></span><span></span></p>
                    </div>
                </div>
            </van-tab> -->
            <!-- 多元资讯 -->
            <!-- <van-tab title="多元资讯" class="item-tab" :name="7">
                <div class="item">
                    <div class="title">多元资讯
                        <div class="consulting-title">
                            <span :class="{'active': consultingActive == 1}" @click="changeConsulting(1)">市场营销</span>
                            <span :class="{'active': consultingActive == 2}" @click="changeConsulting(2)">政策咨询</span>
                            <span :class="{'active': consultingActive == 3}" @click="changeConsulting(3)">惠农助农</span>
                        </div>
                    </div>
                    <div class="content consulting">
                        <p v-for="(item, index) in consultingList" :key="index">
                            <a :href="item.url">{{item.content}}</a>
                            <span>{{item.time}}</span>
                        </p>
                    </div>
                </div>
            </van-tab> -->
             <!-- 通讯地址 -->
            <!-- <van-tab title="通讯地址" class="item-tab" :name="8">
                <div class="item" id="location">
                    <div class="title">通讯地址</div>
                    <div class="divider">
                        <span class="center"></span>
                    </div>
                    <div class="content mailing">
                        <div class="map-container">
                            <div class="map" id="map"></div>
                        </div>
                        <div class="contcat"> 
                            <p><span>联系电话:</span><span>{{companyInfo.companyPhone}}</span></p>
                            <p><span>企业邮箱:</span><span>{{companyInfo.companyEmail}}</span></p>
                            <p><span>企业地址:</span><span>{{companyInfo.companyAddress}}</span></p>
                        </div>
                    </div>
                    <div class="end-bottom"></div>
                </div>
            </van-tab> -->
        </van-tabs>
        <div class="end">
            <span></span>
            <span @click="backTop">返回顶部</span>
            <span></span>
        </div>
        <!-- 左侧导航 -->
        <van-popup v-model="tabShow" position="right" class="pop-up-container" overlay-class="mask-layer">
            <div class="pop-content">
                <div class="pop-header"></div>
                <div class="pop-bottom">
                    <div class="pop-title">
                        <p>全部导航</p>
                        <p>Content navigation</p>
                        <div class="close-pop" @click="closePop">
                            <span>X</span>
                        </div>
                    </div>
                    <div class="pop-content">
                        <div v-for="(item, index) in tabContent" :key="index" :class="{'pop-item': true, 'active': item.id == activeTab}" @click="changeTab(item.id)">{{item.title}}</div>
                    </div>
                    <div class="pop-content-bottom"></div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {getPark, listPageChartConfig} from '@/api/home';
import {
    getOrgId,
    getToken,
    getUserType,
    setToken,
    setOrgId
} from '@/utils/cookie';
import {listPageMassifWork} from '@/api/plotManagement';
export default {
    data() {
        return {
            isTourist: false,//判断是否是游客
            val1: '',
            option1: [
                { text: '产业概况', value: 0 },
                { text: '经济形态', value: 1 },
                { text: '产业布局', value: 2 },
                { text: '特色产品', value: 3 }, 
                { text: '价格行情', value: 4 },
                { text: '农事记事', value: 5 },
                { text: '印象风采', value: 6 },
                { text: '多元资讯', value: 7 },
                { text: '通讯地址', value: 8 },
            ],
            typeFlag: 1,//判断当前选中产品类型
            consultingActive: 1,//当前多元资讯类型
            activeTab: 0,
            tabShow: false, //右侧导航栏是否显示
            map: '',//存储地图
            showBtn: true,//是否显示展开按钮
            describe: '两江新区互联网产业园位于照母山科技创新城核心地带，总面积80万平方米，其中一期面积35万平方米，总投资16亿元；二期面积28万平方米，总投资13.5亿元；三期互联网学院面积16万平方米，总投资9亿元。一期已于2015年10月19日正式开园。二期工程正在建设中，计划2017年底月开园。三期互联网学院已于2016年11月3日正式开院运营。产业园布局了移动互联网等6大新兴产业类型、7大品牌众创空间和互联网学院，互联网产业一期已入驻创新型、创业型企业300多家，其中包括腾讯、春秋航空、猪八戒、易宠科技、微标科技、隆讯科技、海云数据、人民网、中科云丛、易极付、千丁互联、盼达用车等知名、龙头企业50多家，从业人员近10000人，预计2018年底，将有500余家企业入驻，集聚创业创新人才15000多人。',//产业概况
            distribution: '近年来，我国农业结构不断优化，区域布局趋于合理，粮食生产连年丰收，有力保障了国家粮食安全，为稳定经济社会发展大局提供坚实支撑。与此同时，部分地区也出现耕地“非粮化”倾向，一些地方把农业结构调整简单理解为压减粮食生产，一些经营主体违规在永久基本农田上种树挖塘，一些工商资本大规模流转耕地改种非粮作物等，这些问题如果任其发展，将影响国家粮食安全。各地区各部门要坚持以习近平新时代中国特色社会主义思想为指导，增强“四个意识”、坚定“四个自信”、做到“两个维护”，认真落实党中央、国务院决策部署，采取有力举措防止耕地“非粮化”，切实稳定粮食生产，牢牢守住国家粮食安全的生命线。经国务院同意，现提出以下意见。',//产业布局
            products: [ //特色产品
                {name: '赣南脐橙水果', url: require('@/assets/images/home/u131.png')},
                {name: '冬桃苗 巨型冬桃', url: require('@/assets/images/home/u132.png')},
                {name: '新品种，奶油白', url: require('@/assets/images/home/u133.png')},
                {name: '新品种，奶油白', url: require('@/assets/images/home/u133.png')},
                {name: '新品种，奶油白', url: require('@/assets/images/home/u133.png')},
                {name: '新品种，奶油白', url: require('@/assets/images/home/u133.png')},
            ],
            myChart: null,//存储价格详情echarts
            farmingNotes: [ //农事记事
                {context: '稻田浇水', createTime: '2021年02月12日', inputsName: '张利民', url: require('../assets/images/home/icon_note_watering.png')},
                {context: '播前除草', createTime: '2021年02月12日', inputsName: '张利民', url: require('../assets/images/home/icon_note_weeding.png')},
                {context: '果蔬施肥', createTime: '2021年02月12日', inputsName: '张利民', url: require('../assets/images/home/icon_note_fertilize.png')},
                {context: '农药喷洒', createTime: '2021年02月12日', inputsName: '张利民', url: require('../assets/images/home/icon_note_pesticide.png')},
            ],
            swiperList: [require('@/assets/images/home/u131.png'), require('@/assets/images/home/u132.png')],
            consultingList: [//多元咨询
                {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
            ],
            tabContent: [
                {id: 0, title: '产业概况'},
                {id: 1, title: '经济形态'},
                {id: 2, title: '产业布局'}, 
                {id: 3, title: '特色产品'},
                {id: 4, title: '价格详情'},
                {id: 5, title: '农业记事'},
                {id: 6, title: '印象风采'},
                {id: 7, title: '多元资讯'},
                {id: 8, title: '通信地址'},
            ],
            orderArr: [
                {id: 2, title: '特色产品', name: 3,},
                {id: 7, title: '价格行情', name: 4},
                {id: 1, title: '农业记事', name: 5},
                {id: 4, title: '印象风采', name: 6},
                {id: 0, title: '多元资讯', name: 7},
                {id: 5, title: '通信地址', name: 8},
                
            ],
            copyOrderArr: [
                {id: 2, title: '特色产品', name: 3,},
                {id: 7, title: '价格行情', name: 4},
                {id: 1, title: '农业记事', name: 5},
                {id: 4, title: '印象风采', name: 6},
                {id: 0, title: '多元资讯', name: 7},
                {id: 3, title: '多元资讯', name: 7},
                {id: 6, title: '多元资讯', name: 7},
                {id: 5, title: '通信地址', name: 8},
                
            ],
            lnglat: {},
            describeSrc: require('../assets/images/home/u102.png'),//产业概况图片
            economicImg: [ //经济形态
                {desc: '测试测试测试', url: require('../assets/images/home/u131.png')},
                {desc: '测试测试测试1', url: require('../assets/images/home/u132.png')},
                {desc: '测试测试测试2', url: require('../assets/images/home/u133.png')},
            ],
            companyInfo: { //企业信息
                companyAddress: '重庆市渝北区礼嘉智农农业产业园一期1号楼',//企业
                companyEmail: 'chongqingjimi@jimilab.com',//企业邮箱
                companyPhone: '023-67681190',//联系电话
            },
            marketing: [],//市场营销
            policyConsultation: [],//政策咨询
            benefitFarmers: [],//惠农助农
        }
    },
    created() {
        let token = this.getLocationUrl('token');
        let orgId = this.getLocationUrl('orgId');
        if (token) {
            setToken(token);
        }
        if (orgId) {
            setOrgId(orgId);
        }
    },
    mounted() {
        this.isTourist = !getToken() ? true : false;
        let arrData = [20, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 40];
        let arrData2 = [100, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 8];
         if (!this.isTourist) {
            this.getPark();
            this.listPageMassifWork();
         }
        setTimeout( () => {
            if (this.isTourist) {
                this.initMap()
                this.initCharts(arrData, arrData2);
            }
        },0)
        if (getOrgId()) {
            this.listPageChartConfig();
        }
        this.$nextTick(() => {
            window.addEventListener("scroll", this.handleScroll);
        });
    },
    methods: {
        /** 
         * 初始化地图
         */
        initMap() {
            let lng = this.lnglat.lng ? this.lnglat.lng : 106.502305;
            let lat = this.lnglat.lat ? this.lnglat.lat : 29.673574;
            this.map = new BMap.Map('map');
            let point = new BMap.Point(lng,lat);
            this.map.centerAndZoom(point, 15);
            let myIcon = new BMap.Icon(require('@/assets/images/location.svg'),new BMap.Size(40,50));
            let marker = new BMap.Marker(point,{icon:myIcon});
            this.map.addOverlay(marker);
           /*  let html = `<div class="info-window">重庆市渝北区智农农业产业园1栋</div>`;
            let opts = {
                offset: new BMap.Size(-40, 30)
            };
            let infoWindow = new BMapLib.InfoBox(this.map, html,opts);
            infoWindow.open(marker) */
        },
        /** 点击展开显示全部 */
        showMore() {
            this.showBtn = false;
            document.getElementById('desc').style.maxHeight = 'none';
        },
        listPageChartConfig() {
            let obj = {
                orgId: getOrgId(),
                pageSize: 0
            }
            let tempArr = JSON.parse(JSON.stringify(this.copyOrderArr));
            let arr = [];
            listPageChartConfig(obj).then(res => {
                if (res.code == 200) {
                    if (res.data.length > 0) {
                        /* arr = tempArr.filter((item) => {
                            res.data.forEach((v, index) => {

                            })
                        });
                        res.data.map(item => {
                            console.log(item)
                        }) */
                        let flag = true;
                        if (res.data.length > 0) {
                            res.data.forEach(item => {
                                tempArr.forEach(v => {
                                    if (v.title == '多元资讯') {
                                        if (flag) {
                                            if (item.chartId == v.id) {
                                                arr.push(v);
                                            }
                                        }
                                        flag = false;
                                    } else {
                                        if (item.chartId == v.id) {
                                            arr.push(v);
                                        }
                                    }
                                });
                            });
                        } else {
                            arr = this.orderArr;
                        }
                        this.orderArr = arr;
                    }
                }
                this.$nextTick(() => {
                    this.initMap();
                });
                setTimeout(() => {
                    let arrData = [20, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 40];
                    let arrData2 = [100, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 8];
                    this.initCharts(arrData, arrData2);
                }, 0);
            })
        },
        /** 初始化价格详情配置项 */
        initCharts(data, data2) {
            this.myChart = this.$echarts.init(document.getElementById('priceTrend'));
            let options = {
                 tooltip: {
                    trigger: 'axis',
                    confine: true, 
                    formatter: (val) => {
                        let str = '2021/01/02 ' + val[0].axisValue + '时'
                        let html = `<div class="tooltip-bg">
                            <p class="tooltip-title">${str}</p>
                            <p class="tooltip-item">同比增长: ${val[0].value} %</p>
                            <p class="tooltip-item">环比增长: ${val[1].value} %</p>
                        </div>`;
                        return html
                    }
                },
                legend: {
                    show: false,
                },
                grid: {
                    top: '18px',
                    left: '6px',
                    right: '6px',
                    bottom: '12px',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#B8B8B8',  //更改坐标轴文字颜色
                            fontSize : 12      //更改坐标轴文字大小
                        }
                    },
                    data: [0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48]
                },
                 yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    }
                },
                series: [
                    {
                        name: '同比增长',
                        type: 'line',
                        itemStyle : {  
                            normal : {  
                                lineStyle:{  
                                    color:'#FFA458'  
                                }  
                            }  
                        }, 
                        showSymbol: true,
                        symbolSize: 14,
                        symbol: function() {
                            return 'image://' + require('@/assets/images/home/icon_price_yoyl.png');
                        },
                        data: data
                    },
                    {
                        name: '环比增长',
                        type: 'line',
                        itemStyle : {  
                            normal : {  
                                lineStyle:{  
                                    color:'#B0FFA6'  
                                }  
                            }  
                        },
                        showSymbol: true,
                        symbolSize: 14,
                        symbol: function() {
                            return 'image://' + require('@/assets/images/home/icon_price_chainl.png');
                        },
                        data: data2
                    },
                ]
            }
            this.myChart.setOption(options);
        },
        /** 切换产品种类 */
        changeType(type) {
            this.typeFlag = type;
            let arr = [];
            let arr2 = []
            if (type == 1) {
                arr = [20, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 40];
                arr2 = [100, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 8];
            } else if (type == 2) {
                arr = [10, 30, 65, 90, 80, 65, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 100];
                arr2 = [50, 42, 53, 45, 90, 85, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 8];
            } else if (type == 3) {
                arr = [30, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 90];
                arr2 = [100, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 8];
            } else if (type == 4) {
                arr = [5, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 80];
                arr2 = [90, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 8];
            } else if (type == 5) {
                arr = [20, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 48];
                arr2 = [60, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 68];
            } else if (type == 6) {
                arr = [20, 30, 65, 90, 12, 45, 58, 21, 24, 87, 70, 63, 56, 49, 42, 45, 45];
                arr2 = [70, 32, 53, 45, 65, 72, 31, 90, 12, 24, 61, 80, 17, 35, 43, 52, 88];
            }
            this.initCharts(arr, arr2);
        },
        /** 切换多元资讯类型 */
        changeConsulting(type) {
            this.consultingActive = type;
            if (this.isTourist) {
                this.consultingList = [//多元咨询
                    {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                    {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                    {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                    {content: '这是一条资讯的名称不是特别长防静点这是一条资讯的名称不是特别长防静点', time: '2020/02/09', url: 'https://www.baidu.com/'},
                ];
            } else {
                if (type == 1) {
                    this.consultingList = this.marketing;
                } else if (type == 2) {
                    this.consultingList = this.policyConsultation;
                } else if (type == 3) {
                    this.consultingList = this.benefitFarmers;
                }
            }
            
        },
        /** 返回顶部 */
        backTop() {
             let top = document.documentElement.scrollTop || document.body.scrollTop;
            // 实现滚动效果 
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        /** 打开导航 */
        openNav() {
            console.log(this.$refs.menu)
            // this.tabShow = true;
        },
        /** 关闭导航弹窗 */
        closePop() {
            this.tabShow = false;
        },
        /** 切换导航 */
        changeTab(id) {
            this.activeTab = id;
            this.$refs.tabs.scrollTo(id)
        },
        /** 监听滚动条高度 */
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            let dom = document.getElementById('openNav');
            if (scrollTop >= 74) {
                dom.setAttribute('class', 'top-title-right top-title-right-fixed');
            } else {
                dom.setAttribute('class', 'top-title-right');
            }
        },
        /** 切换菜单 */ 
        changeMenu(val) {
            this.activeTab = val
            this.$refs.tabs.scrollTo(val)
        },
        /** 跳转页面 */
        jumpCrop() {
            this.$router.push({path: '/agriculturalRecords'});
        },
        /** 获取农事记录 */
        listPageMassifWork() {
            let obj = {
                pageIndex: 1,
                pageSize: 5,
            }
            listPageMassifWork(obj).then(res => {
                if (res.code == 200) {
                    this.farmingNotes = res.data || [];
                    let urlArr = [require('../assets/images/home/icon_note_watering.png'),require('../assets/images/home/icon_note_weeding.png'),require('../assets/images/home/icon_note_fertilize.png'),
                        require('../assets/images/home/icon_note_pesticide.png'),require('../assets/images/home/icon_note_weeding.png')];
                    this.farmingNotes.forEach((item, index) => {
                        item.url = urlArr[index];
                    });
                }
            })
        },
        /** 获取园区信息 */
        getPark() {
            let obj = {
                orgId: getOrgId() 
            }
            getPark(obj).then(res => {
                if (res.code == 200) {
                    let urlArr = [];//印象风采
                    if (res.data.survey) {
                        let contentArr = res.data.survey.split('+');
                        this.describe = contentArr[0];
                        this.describeSrc = this.getParenthesesStr(contentArr[1]);
                    }
                    if (res.data.demeanour) {
                        if (res.data.demeanour.indexOf(',') != -1) {
                           urlArr = res.data.demeanour.split(',');
                        } else {
                            urlArr.push(res.data.demeanour)
                        }
                        this.swiperList = urlArr; //印象风采
                    }
                    if (res.data.economic.indexOf('@') != -1) {
                        let arr = res.data.economic.split('@');
                        this.economicImg = [];
                        arr.forEach((item, index) => {
                            if(item.indexOf('+') != -1) {
                                let contentArr = item.split('+');
                                let obj = {
                                    desc: contentArr[0],
                                    url: this.getParenthesesStr(contentArr[1])
                                }
                                this.economicImg.push(obj);
                            }
                        });
                    }
                    if (res.data.featured.indexOf('@') != -1) {
                        let arr = res.data.featured.split('@');
                        this.products = [];
                        arr.forEach((item, index) => {
                            if (item.indexOf('+') != -1) {
                                let contentArr = item.split('+');
                                let obj = {
                                    name: contentArr[0],
                                    url: this.getParenthesesStr(contentArr[1])
                                }
                                this.products.push(obj);
                            }
                        })
                    }
                    this.consultingList = [];
                    this.policyConsultation = [];
                    this.benefitFarmers = [];
                    if (res.data.market) {
                        this.marketing = this.transformData(res.data.market);
                        this.consultingList = this.marketing;
                    }
                    if (res.data.consulting) {
                        this.policyConsultation = this.transformData(res.data.consulting);
                    }
                    if (res.data.helpstr) {
                        this.benefitFarmers = this.transformData(res.data.helpstr);;
                    }
                    this.companyInfo = { //企业信息
                        companyAddress: res.data.address,
                        companyEmail: res.data.remark || 'chongqingjimi@jimilab.com',
                        companyPhone: res.data.phoneNum
                    }
                    this.distribution = res.data.layout; //产业布局
                    this.lnglat = res.data.context ? JSON.parse(res.data.context) : '';
                    this.$nextTick(() => {
                        this.initMap();
                    })
                    
                }
                
            })
        },
        /** 获取小括号中的内容 */   
        getParenthesesStr(text) {
            let result = '';
            let regex = /\((.+?)\)/g;
            let options = text.match(regex);
            result = options[0].substring(1, options[0].length - 1)
            return result
        },
        /** 改造数据(市场营销、政策咨询、惠农助农) */
        transformData(data) {
            let result = [];
            if (data.indexOf('@') != -1) {
                let arr = data.split('@');
                arr.forEach((item, index) => {
                    if (item.indexOf('+') != -1) {
                        let tempArr = item.split('+');
                        let obj = {
                            content: tempArr[0],
                            time: tempArr[1],
                            url: tempArr[2]
                        }
                        result.push(obj);
                    }
                })
            } else {
                if (data.indexOf('+') != -1) {
                    let tempArr = item.split('+');
                    let obj = {
                        content: tempArr[0],
                        time: tempArr[1],
                        url: tempArr[2]
                    }
                    result.push(obj);
                }
            }
            return result;
        },
        /** 获取地址栏参数 */
        getLocationUrl(name) {
            var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
            var r = window.location.search.substr(1).match(reg);
            if(r!=null)return  unescape(r[2]); return null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .main-container {
        padding: 0 10px;
        padding-bottom: 16px;
        overflow: hidden;
        background: url(../assets/images/home/img_bg.png) no-repeat;
        .header-title {
            position: relative;
            // height: 35px;
            margin: 0;
            margin-top: 19px;
            height: 35px;
            text-align: left;
            color: #fff;
            background: url(../assets/images/home/top-title-line.png) no-repeat;
            background-size: contain;
            background-position: 0 80%;
            .title-text {
                position: absolute;
                top: -3px;
                left: 15px;
                font-size: 20px;
                font-weight: bold;
            }
            &::before {
                position: absolute;
                left: 0;
                bottom: 13px;
                content: '';
                width: 0;
                height: 0;
                border-left: 8px solid #9FF595;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
        }
        
        .top-title-right {
            display: inline-block;
            position: fixed;
            top: 83px;
            right: 4px; 
            // margin: 0;
            // margin: 50px;
            width: 32px;
            height: 22px;
            line-height: 22px;
            background-image: -moz-linear-gradient(to right, rgba(0,0,0,0) , rgba(23, 23, 23, 1));
            background-image: -o-linear-gradient(to right, rgba(0,0,0,0) , rgba(23, 23, 23, 1));
            background-image: -webkit-linear-gradient(to right, rgba(0,0,0,0) , rgba(23, 23, 23, 1));
            background-image: linear-gradient(to right, rgba(0,0,0,0) , rgba(23, 23, 23, 1));
            z-index: 9;
            img {
                float: right;
                width: 24px;
                height: 22px;
                vertical-align: middle;
            }
            &.top-title-right-fixed {
                top: 9px;
            }
        }
        
        .top-tab {
            margin-top: 20px;
        }
        .item-tab {
            &:nth-of-type(1) {
                padding-top: 20px;
            }
            padding-top: 30px;
        }
        .item {
            padding-top: 16px;
            .divider {
                position: relative;
                width: 356px;
                height: 1px;
                margin: 0 auto;
                margin-top: 20px;
                background-color: #B0FFA6;
                .center {
                    position: absolute;
                    top: -1px;
                    left: 175px;
                    width: 21px;
                    height: 3px;
                    box-shadow: 0px 0px 2px 0px rgba(176,255,166,1);
                    background-color: #B0FFA6;
                }
            }
            .divider::before, 
            .divider::after{
                position: absolute;
                top: -1px;
                width: 4px;
                height: 4px;
                content: '';
                border-radius: 50%;
                background-color: #B0FFA6;
            }
            .divider::after {
                right: 0;
            }
            .bottom-divider {
                margin-top: 29px;
            }
            .title {
                width: 356px;
                margin: 0 auto;
                padding: 9px 19px;
                font-size: 16px;
                color: #fff;
                background: url(../assets/images/home/img_tittle.png) no-repeat;
                background-size: cover;
            }
            .content {
                margin-top: 20px;
                .img-bg {
                    width: 343px;
                    height: 160px;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    img {
                        width: 332px;
                        height: 160px;
                        margin: 0 auto;
                        border-radius: 8px;
                    }
                }
                .industry-bg {
                    /* width: 332px;
                    margin: 0 auto;
                    border-radius: 8px;
                    background: url(../assets/images/home/u102.png) no-repeat;
                    background-size: cover; */
                }
            }
            /* 产业概况 */
            .industry-overview {
                width: 332px;
                margin: 0 auto;
                .desc-content {
                    position: relative;
                    width: 100%;
                    margin-top: 12px;
                    font-size:14px;
                    // max-height: 80px;
                    color: #E8E8E8;
                    text-align: justify; 
                    overflow: hidden;
                }
               /*  .box::after{
                    content: attr(content);
                    overflow: hidden;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 0;
                    color:#fff;
                    line-height: 20px;
                }
                .box::after{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    -webkit-line-clamp: 4;
                    text-indent: -2em;
                    padding-right: 2em;
                }
                .box {
                    color: #fff;
                    .see-more{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        z-index: 3;
                        color: #fff;
                        line-height: 24px;
                    }
                } */
                .divider-bg {
                    width: 332px;
                    height: 4px;
                    margin-top: 10px;
                    margin-bottom: 16px;
                    background: url(../assets/images/home/img_gradientbar-green.png) no-repeat;
                    background-size: contain;
                    background-position: right;
                }
            }
            /* 市场营销 */
            .marketing {
                p {
                    margin: 0;
                    a {
                        font-size: 14px;
                    }
                }
                
            }
            /* 经济形态 */
            .economic {
                position: relative;
                width: 356px;
                height: 220px;
                margin: 0 auto;
                margin-top: 20px;
                padding-top: 35px;
                // border: 1px dotted #fff;
                background: url(../assets/images/home/img_economic_bg.png) no-repeat;
                background-size: contain;
                .img-container {
                    position: absolute;
                    text-align: center;
                    background: url(../assets/images/home/img_economic_frame.png) no-repeat;
                    background-size: contain;
                    img {
                        vertical-align: middle;
                    }
                    &.prev,&.next {
                        top: 72px;
                        width: 89px;
                        height: 68px; 
                        line-height: 68px;
                        img {
                            width: 83px;
                            height: 61px;
                        }
                    }
                    &.prev {
                        left: -30px;
                    }
                    &.active {
                        top: 35px;
                        left: 89px;
                        width: 178px;
                        height: 138px;
                        line-height: 138px;
                        img {
                            width: 166px;
                            height: 123px;
                        }
                    }
                    &.next {
                        right: -30px;
                    }
                }
                .img-desc {
                    position: absolute;
                    top: 200px;
                    width: 355px;
                    font-size: 14px;
                    text-align: center;
                    color: #CBF3CB;
                }
            }
            /* 特色产品 */
            .products-more {
                float: right;
                padding-right: 16px;
                font-size: 12px;
                color: #69B66B;
            }
            .products {
                width: 356px;
                .products-item {
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    .products-img {
                        width: 108px;
                        height: 108px;
                        border: 1px solid #6CBB6E;
                        img {
                            width: 108px;
                            height: 108px;
                        }
                    }
                    p {
                        width: 110px;
                        height: 20px;
                        margin: 0;
                        margin-top: 5px;
                        line-height: 18px;
                        background: url(../assets/images/home/img_products_textbox.png) no-repeat;
                        background-size: contain;
                        span {
                            display: inline-block;
                            width: 80px;
                            // height: 20px;
                            margin-left: 5px;
                            line-height: 18px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                }
                .products-item.m-r0 {
                    margin-right: 0;
                }
            }
            /* 价格行情 */
            .title-right {
                float: right;
                padding-right: 16px;
                font-size: 12px;
                line-height: 22px;
                span:nth-of-type(1) {
                    position: relative;
                    margin-right: 30px;
                    color: #FFA458;
                }
                span:nth-of-type(2) {
                    position: relative;
                    color: #5DA660;
                }
                .yoy-growth::before,
                .chain-growth::before {
                    content: '';
                    position: absolute;
                    top: 1px;
                    left: -16px;
                    width: 14px;
                    height: 14px;
                }
                .yoy-growth::before {
                    background: url(../assets/images/home/icon_price_yoyl.png) no-repeat;
                    background-size: contain;
                }
                .chain-growth::before {
                    background: url(../assets/images/home/icon_price_chainl.png) no-repeat;
                    background-size: contain;
                }
            }
            .price-quotation {
                width: 356px;
                .price {
                    width: 356px;
                    height: 195px;
                    background: rgba(47, 60, 47, 0.3);
                    background: url(../assets/images/home/img_price_chartbg.png) no-repeat;
                    background-size: contain;
                    background-position: 0 32%;
                }
                .type-price {
                    width: 330px;
                    height: 22px;
                    margin: 0 auto;
                    margin-top: 16px;
                    text-align: center;
                    .type-item {
                        display: inline-block;
                        width: 45px;
                        height: 20px;
                        font-size: 12px;
                        text-align: center;
                        line-height: 22px;
                        color: #4E6B4C;
                        background: #171717;
                        border: 1px solid #6DA268;
                        &.active {
                            color: #fff;
                            background: #425D3F;
                        }
                    }
                }
            }
            /* 农事记事 */
            .farming {
                .farming-item {
                    display: flex;
                    width: 320px;
                    height: 52px;
                    margin: 0 auto;
                    .left {
                        width: 32px;
                        margin-right: 12px;
                        img {
                            width: 32px;
                            height: 32px;
                        }
                        .vertical-line {
                            width: 0;
                            height: 22px;
                            margin: 0 auto;
                            border-left: 1px dotted #385738;
                        }
                    }
                    .right {
                        flex: 1;
                        width: 258px;
                        .right-title {
                            margin: 0;
                            font-size: 14px;
                            color: #DFDFDF;
                        }
                        .right-other {
                            margin: 0;
                            margin-top: 4px;
                            span {
                                font-size: 12px;
                            }
                            span:nth-of-type(1) {
                                float: left;
                                color: #787878;
                            }
                            span:nth-of-type(2) {
                                float: right;
                                color: #787878;
                            }
                        }
                    }
                }
            }
            /* 印象风采 */
            .impression {
                .impression-img {
                    width: 332px;
                    height: 194px;
                    margin: 0 auto;
                    line-height: 194px;
                    border: 1px solid #68B56A;
                    .my-swipe {
                        width: 320px;
                        height: 180px;
                        border-radius: 8px;
                        margin: 0 auto; 
                        img {
                            width: 320px;
                            height: 180px;
                            border-radius: 8px;
                            vertical-align: middle;
                        }
                    }
                }
                .impression-bottom {
                    width: 332px;
                    height: 14px;
                    line-height: 14px;
                    margin: 0 auto;
                    span {
                        display: inline-block;
                        height: 14px;
                    } 
                    span:nth-of-type(1) {
                        width: 196px;
                        background: url(../assets/images/home/img_impression_bottomsquare.png) no-repeat;
                        background-size: contain;
                    }
                    span:nth-of-type(2) {
                        width: 136px;
                        background: url(../assets/images/home/img_impression_decoration.png) no-repeat;
                        background-size: contain;
                        background-position: right;
                    }
                }
            }
            /* 多元咨询 */
            .consulting-title {
                float: right;
                padding-right: 16px;
                font-size: 12px;
                line-height: 22px;
                span {
                    margin-left: 8px;
                    font-size: 12px;
                    color: #929292;
                    &.active {
                        font-size: 14px;
                        font-weight: 500;
                        color: #FDB543;
                    }
                }
            }
            .consulting {
                width: 356px;
                margin: 0 auto;
                margin-top: 15px;
                padding: 16px 0;
                p {
                    position: relative;
                    width: 336px;
                    margin: 0 auto;
                    margin-top: 16px;
                    a {
                        display: inline-block;
                        width: 250px;
                        line-height: 28px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        border-bottom: 1px solid #B5E2B5;
                        color: #B5E2B5;
                    }
                    span {
                        float: right;
                        line-height: 34px;
                        font-size: 14px;
                        color: #A9A9A9;
                    }
                }
            }
            /* 通讯地址 */
            .mailing {
                .map-container {
                    position: relative;
                    width: 256px;
                    height: 182px;
                    margin: 0 auto;
                    background: url(../assets/images/home/img_address_frame.png) no-repeat;
                    background-size: contain;
                    .map {
                        position: absolute;
                        top: 54%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 244px;
                        height: 153px;
                        border-radius: 8px;
                    }
                }
                .contcat {
                    width: 310px;
                    margin: 0 auto;
                    margin-top: 22px;
                    p {
                        margin: 0;
                        margin-top: 4px;
                        text-align: center;
                        font-size: 12px;
                        span:nth-of-type(1) {
                            color: #fff;
                        }
                        span:nth-of-type(2) {
                            margin-left: 4px;
                            color: #fff;
                        }
                    }
                }
            }
            .end-bottom {
                width: 348px;
                height: 4px;
                margin: 0 auto;
                margin: 27px 0;
                background: url(../assets/images/home/img_gradientbar-orange.png) no-repeat;
                background-size: contain;
            }
        }
        .end {
            width: 356px;
            margin: 0 auto;
            margin-top: 40px;
            line-height: 28px;
            text-align: center;
            span {
                display: inline-block;
                height: 28px;
                vertical-align: middle;
            }
            span:nth-of-type(1) {
                width: 43px;
                margin-right: 12px;
                background: url(../assets/images/home/img_button-cube.png) no-repeat;
                background-size: contain;
                background-position: right;
            }
            span:nth-of-type(2) {
                width: 168px;
                font-size: 12px;
                color: #fff;
                background: url(../assets/images/home/button_backtotop.png) no-repeat;
                background-size: contain;
                background-position: right;
            }
            span:nth-of-type(3) {
                width: 43px;
                margin-left: 12px;
                background: url(../assets/images/home/img_button-cube.png) no-repeat;
                background-size: contain;
                background-position: left;
            }
        }
        /* 弹出层 */
        .pop-up-container {
            width: 232px;
            height: 100%;
            background-color: RGBA(33, 40, 33, 1);
            .pop-content {
                .pop-header {
                    width: 232px;
                    height: 10px;
                    background-color: rgba(80, 142, 81, 1);
                }
                .pop-bottom {
                    width: 232px;
                    padding: 16px;
                    .pop-title {
                        position: relative;
                        p {
                            margin: 0;
                        }
                        p:nth-of-type(1) {
                            font-size: 16px;
                            line-height: 24px;
                            color: #DEDEDE;
                        }
                        p:nth-of-type(2) {
                            font-size: 12px;
                            line-height: 14px;
                            color: #325933;
                        }
                        .close-pop {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            top: 0;
                            right: 35px;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            border: 1px solid #95F791;
                            box-shadow: 0px 0px 1px 0px rgba(149,247,145,1); 
                            span {
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                margin: 0 auto;
                                padding: 1px;
                                font-size: 12px;
                                text-align: center;
                                transform: scale(0.9);
                                color: #95F791;
                                background-color: #508E51;
                            }
                        }
                    }
                    .pop-content {
                        width: 202px;
                        height: 530px;
                        margin: 0 auto;
                        margin-top: 15px;
                        background-color: #121312;
                        .pop-item {
                            width: 202px;
                            height: 40px;
                            font-size: 14px;
                            padding-left: 20px;
                            line-height: 40px;
                            color: #8E8E8E;
                            &.active {
                                color: #fff;
                                background: -moz-linear-gradient(270deg, rgba(136, 193, 137, 0) 0%, #508E51 100%);
                                background: -o-linear-gradient(270deg, rgba(136, 193, 137, 0) 0%, #508E51 100%);
                                background: -wenkit-linear-gradient(270deg, rgba(136, 193, 137, 0) 0%, #508E51 100%);
                                background: linear-gradient(270deg, rgba(136, 193, 137, 0) 0%, #508E51 100%);
                            }
                        }
                    }
                    .pop-content-bottom {
                        width: 202px;
                        height: 20px;
                        margin: 0 auto;
                        margin-top: 16px;
                        background: url(../assets/images/home/img_nav_button.png) no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
.main-container {
    .info-window {
        width: 190px;
        padding: 12px;
        border-radius: 8px;
        font-size: 14px;
        color: #262626;
        background-color: #fff;
    }
    .info-window:after {
        position: absolute;
        left: 48%;
        bottom: -10px;
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
    }
    .infoBox img {
        display: none;
    }
    /* tab样式 */
    .van-tabs__nav {
        background-color: #000;
    }
    .van-tab.van-tab--active {
        font-size: 16px;
        color: #fff;
    }
    .van-tabs__line {
        width: 28px;
        height: 4px;
        background: #94E38B;
        box-shadow: 0px 0px 4px 0px rgba(120,230,128,1);
        border-radius: 2px;
    }
    .van-tab {
        font-size: 14px;
        color: #929292;
    }
    .van-tabs__nav--complete {
        padding-left: 0;
    }
    .tooltip-bg {
        width: 103px;
        height: 78px;
        background: url(../assets/images/home/img_price_databoard.png) no-repeat;
        background-size: contain;
    }
    .tooltip-title {
        margin: 0;
        padding-top: 4px;
        font-size: 12px;
        transform: scale(0.9);
        text-align: center;
        color: #D7D7D7;
    }
    .tooltip-item {
        margin: 0;
        padding-left: 5px;
        font-size: 12px;
        transform: scale(0.9);
        text-align: left;
        color: #C6C6C6;
    }
    .van-swipe__indicators {
        left: 90%;
    }
    .van-swipe__indicator {
        opacity: 1;
        background-color: #fff;
    }
    .van-swipe__indicator--active {
        width: 25px;
        height: 4px;
        border-radius: 3px;
        background-color: #69B56B !important
    }
    .van-overlay {
        background: rgba(0,0,0,.3);
    }
    .van-dropdown-menu__bar {
        background-color: transparent;
    }
    .van-dropdown-menu__title--active::after,
    .van-dropdown-menu__title::after {
        border-color: transparent;
    } 
    .van-dropdown-menu__bar {
        position: absolute;
        width: 32px;
        height: 22px;
        margin-right: 0;
        background-color: transparent;
    }
    .van-dropdown-item__option {
        display: inline-block;
        width: 70px;
        text-align: center;
        margin-left: 5px;
        padding: 10px 5px 0 5px;
       /*  padding-top: 16px;
        padding-left: 5px;
        padding-bottom: 0; */
    }
    .van-popup {
        background-color: rgba(0,0,0,.8);
        box-shadow: 0 4px 12px rgba(40, 120, 255,.11) 0 3px 14px rgba(0, 0, 0, .11);
    }
    .van-cell {
        color: #8E8E8E;
        box-shadow: 0 4px 12px rgba(40, 120, 255,.11) 0 3px 14px rgba(0, 0, 0, .11);
        background-color: transparent;
    }
    .van-cell::after {
        border-bottom: 0;
    }
    .van-dropdown-menu__title--active {
        color: transparent;
    }
    .van-dropdown-item__option--active .van-dropdown-item__icon {
        display: none;
    }
    .van-dropdown-item__option--active .van-cell__title {
        color: #fff;
    }
    .top-title-right-fixed .van-dropdown-item--down {
        top: 0 !important;
    }
    .van-dropdown-item--down {
        top: 72px !important;
    } 
}
    
</style>