
import request from '@/utils/request';
/** 列表 */
export function getPark(data) {
    return request({
        url: '/park/getPark',//请求路径
        method: 'get',
        params: data
    });
}
/** 查询用户所配顺序 */
export function listPageChartConfig(data) {
    return request({
        url: '/chartConfig/listPageChartConfig',
        method: 'get',
        params: data
    })
}