import request from '@/utils/request';
//获取地块信息列表
export function listPageMassif(data) {
    return request({
        url: '/cqjimi/massifService/listPageMassif',//请求路径
        method: 'post',
        data
    });
}
//新增地块
export function addMassif(data) {
    return request({
        url: '/cqjimi/massifService/addMassif',//请求路径
        method: 'post',
        data
    });
}
//修改地块
export function modifyMassif(data) {
    return request({
        url: '/cqjimi/massifService/modifyMassif',//请求路径
        method: 'post',
        data
    });
}
//删除地块
export function deleteMassif(data) {
    return request({
        url: '/cqjimi/massifService/deleteMassif',//请求路径
        method: 'post',
        data
    });
}
/** 启停地块 */
export function listPageMassifWork(data) {
    return request({
        url: '/massifWork/listPageMassifWork',//请求路径
        method: 'get',
        params: data
    });
}
/* 地块明细 */
export function addMassifWork(data) {
    return request({
        url: '/massifWork/addMassifWork',
        method: 'post',
        data
    })
}

/* 新增地块作业 */
export function getMassifWork(data) {
    return request({
        url: '/massifWork/getMassifWork',
        method: 'get',
        params: data
    })
}

/* 修改地块作业 */
export function modifyMassifWork(data) {
    return request({
        url: '/massifWork/modifyMassifWork',
        method: 'post',
        data
    })
}

/* 上传作物图片 */
export function uploadImg(data) {
    return request({
        url: 'http://172.26.202.35:8100/nj/file/upload',
        method: 'post',
        data
    })
}
/** 查询所有园区 */
//修改组织信息
export function listTreeOrg(data) {
    return request({
        url: 'rbac/org/getTheNextSubAllOrg',//请求路径
        method: 'get',
        params:data
    });
}
export function listPageInputs(data) {
    return request({
        url: '/inputs/listPageInputs',//请求路径
        method: 'get',
        params:data
    });
}